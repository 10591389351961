/*
 * Title:   Travelo - Travel, Tour Booking HTML5 Template - Custom Javascript file
 * Author:  http://themeforest.net/user/soaptheme
 */

tjq(document).ready(function() {
    // UI Form Element

    /**
    * Eventos relacionados con la vista http://localhost:3000/destinos
    */
    tjq(".mega-href").on("click", function () {
    	var url = tjq(this).attr("rel");
    	var title = tjq(this).attr("title");
    	tjq("#mega-iframe").attr('src', url);
    	tjq("#mega-iframe-title").html(title);
    });
});